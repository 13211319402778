import {createSlice} from "@reduxjs/toolkit";

export const initialState = {
    tasks: null
}

const appSlice = createSlice({
    name: "tasks",
    initialState,
    reducers: {
       setTasks(state, action) {
           state.tasks = action.payload
       }
    }
})

export const {
    setTasks
} = appSlice.actions

export default appSlice.reducer