import Home from "../pages/Home";
import {Navigate} from "react-router-dom";
import Profile from "../pages/Profile";
import Rewards from "../pages/Rewards";
import Friends from "../pages/Friends";

export const authProtectedRoutes = [
    {path: '/lotteries', component: <Home/>},
    {path: '/profile', component: <Profile/>},
    {path: '/rewards', component: <Rewards/>},
    {path: '/friends', component: <Friends/>},
    {
        path: "/",
        exact: true,
        component: <Navigate to="/lotteries" />,
    },
    { path: "*", component: <Navigate to="/lotteries" /> },
]