import {APIClient} from "./api_helper";

const api = new APIClient()

export const authTgUser = (data) => api.create('/auth', data)

export const getRandomPrize = () => api.get('/games/roulette')

export const getTasksApi = () => api.get('/tasks')

export const checkTaskApi = (task) => api.get(`/tasks/${task.id}/check`)