import React from 'react';
import { Routes, Route } from "react-router-dom";
import {authProtectedRoutes} from "./authRoutes";
import Layout from "../Layouts";
import AuthProtected from "./AuthProtected";

const Index = () => {
    return (
        <React.Fragment>
            <Routes>
                {authProtectedRoutes.map((route, index) => (
                    <Route
                        path={route.path}
                        element={
                            <AuthProtected>
                                <Layout>
                                    {route.component}
                                </Layout>
                            </AuthProtected>
                        }
                        key={index}
                        exact={true}
                    />
                ))}
            </Routes>
        </React.Fragment>
    )
}

export default Index