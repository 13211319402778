import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@telegram-apps/telegram-ui/dist/styles.css';
import {AppRoot, Badge} from '@telegram-apps/telegram-ui';
import Route from './Routes';
import React, {useEffect} from "react";
import {useDispatch} from "react-redux";
import {GiCowled, GiEmerald, GiRoundStar, GiThreeFriends} from "react-icons/gi";
import {useLocation} from "react-router-dom";
import {setCurrentTabbarItem} from "./slices/app/reducer";

export const App = () => {
    const dispatch = useDispatch()
    const location = useLocation()
    const tabs = [
        {id: 'rewards', text: 'Rewards', path: "/rewards"},
        {id: 'lottos', text: 'Lottos', path: "/lotteries"},
        {id: 'friends', text: 'Friends', path: "/friends"},
        {id: 'profile', text: 'Profile', path: "/profile"}
    ]

    useEffect(() => {
        let pathname = location.pathname
        let currentTab = tabs.find(el => el.path == pathname)
        dispatch(setCurrentTabbarItem(currentTab?.id))
    }, [tabs])

    return (
        <AppRoot>
            <Route/>
        </AppRoot>
    )
};
