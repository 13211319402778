import {ModalHeader} from "@telegram-apps/telegram-ui/dist/components/Overlays/Modal/components/ModalHeader/ModalHeader";
import {ModalClose} from "@telegram-apps/telegram-ui/dist/components/Overlays/Modal/components/ModalClose/ModalClose";
import {Icon28Close} from "@telegram-apps/telegram-ui/dist/icons/28/close";
import {Button, Headline, Modal, Title} from "@telegram-apps/telegram-ui";
import React, {useState} from "react";
import Lottie from "lottie-react";
import lottieRoulette from "../../assets/lottie/roulette.json"
import ModalWheel from "./ModalWheel";

const ModalSubscribe = (props) => {
    const {modalClose, isOpen} = props
    const [loading, setLoading] = useState(false)
    const [modalWheelIsOpen, setModalWheelIsOpen] = useState(false)
    const onCheckSubscription = () => {
        setLoading(true)
        setTimeout(() => {
            setLoading(false)
            setModalWheelIsOpen(true)
        }, 1000)
    }

    const onModalWheelClose = () => {
        setModalWheelIsOpen(false)
    }
    return (
        <React.Fragment>
            <ModalWheel
                modalClose={onModalWheelClose}
                isOpen={modalWheelIsOpen}
                selectedRoulette = {isOpen}
            />
            <Modal
                dismissible={true}
                header={
                    <ModalHeader
                        after={
                            <ModalClose
                                onClick={() => modalClose()}
                            >
                                <Icon28Close style={{color: 'var(--tgui--plain_foreground)'}} />
                            </ModalClose>
                        }
                    >
                    </ModalHeader>
                }
                open={isOpen}
            >
                <div className={"d-flex w-100 flex-column gap-3 p-3 justify-content-center align-items-center"}>
                    <Lottie
                        style={{
                            width: 200
                        }}
                        animationData={lottieRoulette}
                        loop={true}
                    />
                    <Title
                        level="1"
                        weight="2"
                    >
                        Follow on Roulette
                    </Title>
                    <Headline
                        weight={3}
                    >
                        Subscribe to spin
                    </Headline>
                    <div className={"d-flex w-100 flex-column gap-3"}>
                        <Button
                            onClick={() => {
                                window.Telegram.WebApp.showPopup({
                                    title: "test",
                                    message: "Test"
                                })
                            }}
                        >Subscribe</Button>
                        <Button
                            onClick={() => onCheckSubscription()}
                            loading={loading}
                            mode={"bezeled"}
                        >
                            Check Subscription
                        </Button>
                    </div>

                </div>
            </Modal>
        </React.Fragment>
    )
}

export default ModalSubscribe