import {Card, Subheadline, Image, Headline, Avatar, Caption, Button} from "@telegram-apps/telegram-ui";
import Icon40StarFill from "../../Components/icons/Icon40StarFill";
import IconTon from "../../Components/icons/IconTon";
import React from "react";

const FriendsList = () => {
    const friends = [
        {name: "GRP", username: "xHubdmV_mdubGdbkedgmskd"},
        {name: "GRP", username: "Groov1291"},
        {name: "GRP", username: "Groov129"},
        {name: "GRP", username: "Groov129"}
    ]

    const handleError = (e, src) => {
        debugger
        e.target.src = src
    }
    return (
        <div className={"mt-2 d-block"}>
            <Subheadline className={"text-grey ms-3"}>Friends (10 000)</Subheadline>
            <Card className={"d-block w-100 mt-2 p-3"}>

                <div className={"d-flex flex-column gap-3"}>
                    {friends && friends.map((el, i) => (
                        <div key={i} className={"task d-flex flex-row justify-content-between align-items-center"}>
                            <div className={"d-flex flex-row gap-2 align-items-center w-75"}>
                                <div className={""}>
                                    <Avatar
                                        acronym={'GR'}
                                        size={40}
                                        src={`https://unavatar.io/telegram/${el.username}`}
                                    />
                                </div>

                                <div className={"d-flex flex-column"}>
                                    <Subheadline className={"text-break"} weight={2}>
                                        @{el.username}
                                    </Subheadline>
                                    <Caption weight={3}>
                                        12.03.2014
                                    </Caption>

                                </div>
                            </div>
                            <div className={"d-flex bonus-amount align-items-center gap-1"}>
                                <span>
                                    + 50
                                </span>
                                <span>
                                    <Icon40StarFill className={"transaction-icon"} />
                                </span>
                            </div>
                        </div>
                    ))}
                </div>
                <div className={"pagination d-flex justify-content-center pt-2"}>
                    <Button
                        mode={"plain"}
                    >Show more</Button>
                </div>
            </Card>
        </div>

    )

}

export default FriendsList