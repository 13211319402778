import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import {App} from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { configureStore } from "@reduxjs/toolkit";
import rootReducer from "./slices";
import {TonConnectUIProvider} from "@tonconnect/ui-react";


const store = configureStore({ reducer: rootReducer, devTools: true });
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.Fragment>
      <Provider store={store}>
          <TonConnectUIProvider manifestUrl="https://app.afomenko.tech/tonconnect-manifest.json">
              <BrowserRouter basename={process.env.PUBLIC_URL}>
                  <App />
              </BrowserRouter>
          </TonConnectUIProvider>
      </Provider>
  </React.Fragment>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
