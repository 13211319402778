import { combineReducers } from "redux";
import AuthReducer from "./auth/reducer"
import AppReducer from "./app/reducer"
import TaskReducer from "./tasks/reducer"

const rootReducer = combineReducers({
    Auth: AuthReducer,
    App: AppReducer,
    Task: TaskReducer
})

export default rootReducer