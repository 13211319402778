import {Badge, Tabbar} from "@telegram-apps/telegram-ui";
import {useState} from "react";
import React from 'react';
import {Icon24Person} from "../Components/icons/Icon24Person";
import {Icon24Link} from "../Components/icons/Icon24Link";
import {useNavigate} from "react-router-dom";
import { GiRoundStar } from "react-icons/gi";
import { GiEmerald } from "react-icons/gi";
import { GiCowled } from "react-icons/gi";
import { GiThreeFriends } from "react-icons/gi";
import {useDispatch, useSelector} from "react-redux";
import {setCurrentTabbarItem} from "../slices/app/reducer";

const Footer = () => {

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const {currentTab} = useSelector((state) => state.App)

    const tabs = [
        {id: 'rewards', text: 'Rewards', Icon : <React.Fragment>
                <Badge
                    className={"position-absolute start-50"}
                    mode="critical"
                    type="number"
                >
                    0
                </Badge>
                <GiRoundStar size={26}/>
            </React.Fragment>, path: "/rewards"},
        {id: 'lottos', text: 'Lottos', Icon : <GiEmerald size={26} />, path: "/lotteries"},
        {id: 'friends', text: 'Friends', Icon : <GiThreeFriends size={26} />, path: "/friends"},
        {id: 'profile', text: 'Profile', Icon : <GiCowled size={26}/>, path: "/profile"}
    ]



    const changeTab = (tab) => {
        if (window.Telegram.WebApp)
            window.Telegram.WebApp.HapticFeedback.impactOccurred('soft')
        dispatch(setCurrentTabbarItem(tab.id))
        navigate(tab.path)
    }

    return (
        <Tabbar className={"footer"} style={{zIndex: 10, position:"sticky"}}>
            {tabs.map((el, i) =>
                <Tabbar.Item className={"tabbar-item"} key={i} text={el.text} selected={el.id === currentTab} onClick={() => changeTab(el)}>
                    {el.Icon}
                </Tabbar.Item>
            )}
        </Tabbar>
    )
}

export default Footer