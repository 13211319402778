import Footer from "./Footer";
import React from 'react'
import 'react-toastify/dist/ReactToastify.css';
import {Flip, ToastContainer} from "react-toastify";
import Confetti from "react-confetti-boom";
import {useSelector} from "react-redux";
const Layout = (props) => {
    const {showConfetti} = useSelector(state => state.App)
    return (
        <React.Fragment>
            {showConfetti && <div className={"confetti"}>
                <Confetti particleCount={500} mode={"boom"} />
            </div>}
            <ToastContainer
                position={"bottom-center"}
                theme={'dark'}
                autoClose={2000}
                hideProgressBar={true}
                transition={Flip}
                limit={2}
            />
            <div className={"content"}>
                <div className={"content-scroll"}>
                    <div className={"tg-secondary-bg"}>
                        <div className={"min-page-content-height p-2"}>
                            {props.children}
                        </div>
                        <Footer />
                    </div>
                </div>
            </div>
        </React.Fragment>

    )
}

export default Layout