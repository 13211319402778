import {Button, Caption, Card, Headline, IconButton, Title} from "@telegram-apps/telegram-ui";
import {Icon20Copy} from "@telegram-apps/telegram-ui/dist/icons/20/copy";
import {IoCopy} from "react-icons/io5";
import Lottie from "lottie-react";
import Icon40StarFill from "../../Components/icons/Icon40StarFill";
import React from "react";
import star_05 from "../../assets/lottie/dotlottie/star_05.lottie";
import star_17 from "../../assets/lottie/dotlottie/star_17.lottie";
import {DotLottieReact} from "@lottiefiles/dotlottie-react";

const InviteFriend = () => {
    return (
        <Card className={"d-block w-100"}>
            <div className={"d-flex w-100 flex-column p-2 gap-2"}>
                <div className={"d-flex flex-column gap-2"}>
                    <div className={"d-flex align-items-center gap-2"}>
                        <div className={"sticker"}>
                            <DotLottieReact
                                style={{
                                    width: 100,
                                    height: 80
                                }}
                                src={star_05}
                                loop
                                autoplay
                            />
                        </div>
                        <div className={"d-flex flex-column"}>
                            <Headline weight={1}>
                                Invite a friend
                            </Headline>
                            <Caption className={"text-warning d-flex gap-1 align-items-center"}>
                                + 50  <Icon40StarFill className={"friend-reward-icon"} /> to you and your friend
                            </Caption>
                        </div>
                    </div>
                    <div className={"d-flex align-items-center gap-2"}>
                        <div className={"sticker"}>
                            <DotLottieReact
                                style={{
                                    width: 100,
                                    height: 80
                                }}
                                src={star_17}
                                loop
                                autoplay
                            />
                        </div>
                        <div className={"d-block"}>
                            <Headline>
                                Invite a friend with Telegram Premium
                            </Headline>
                            <Caption className={"text-warning d-flex gap-1 align-items-center"}>
                                + 75 <Icon40StarFill className={"friend-reward-icon"} /> to you and your friend
                            </Caption>
                        </div>

                    </div>
                </div>
                <div className={"d-flex gap-3 align-items-center"}>
                    <Button
                        className={"w-100"}
                    >
                        Invite friend
                    </Button>
                    <IconButton
                        size={"l"}
                        mode={"bezeled"}
                    >
                        <IoCopy size={28}/>
                    </IconButton>
                </div>

            </div>
        </Card>
    )
}

export default InviteFriend