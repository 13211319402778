import {getTasksApi} from "../../helpers/real_api_helper";
import {setTasks} from "./reducer";


export const getTasks = () => async (dispatch) => {
    try {
        getTasksApi()
            .then(res => {
                dispatch(setTasks(res.data))
            })
            .catch(err => {
                console.log(err)
            })
    }
    catch (error) {

    }
}