import {Caption, Card, Cell, Headline, Modal, Skeleton, Subheadline} from "@telegram-apps/telegram-ui";
import Task from "../../Components/custom/Task";
import {TfiYoutube} from "react-icons/tfi";
import ModalTask from "../../Components/custom/ModalTask";
import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getTasks} from "../../slices/tasks/thunk";
import TaskSkeleton from "./TaskSkeleton";

const Tasks = () => {
    const [selectedTask, setTask] = useState(null)
    const dispatch = useDispatch()
    const {tasks} = useSelector(state => state.Task)

    useEffect(() => {
        dispatch(getTasks())
    }, [dispatch])
    const modalClose = () => {
        setTask(null)
    }

    const selectTask = (el) => {
       setTask(el)
    }
    return (
        <>
            <ModalTask
                task={selectedTask}
                isOpen={selectedTask}
                modalClose={modalClose}
            />
            <Subheadline className={"text-grey ms-3"}>Tasks</Subheadline>
            <Card className={"d-flex flex-column mt-2 p-3 gap-3"}>
                {!tasks && [1,2].map(el => (
                    <TaskSkeleton />
                ))}

                {tasks && tasks.map((el, i) => (
                    <Task
                        onClick={() => selectTask(el)}
                        key={i}
                        task={el}
                    />
                    )
                )}
            </Card>
        </>

    )
}

export default Tasks