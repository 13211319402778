import {Image, Skeleton, Subheadline} from "@telegram-apps/telegram-ui";
import React from "react";

const TaskSkeleton = () => {
    return (
        <div className={"task d-flex flex-row justify-content-between align-items-center"} role={"button"}>
            <div className={"d-flex flex-row gap-2 align-items-center w-75"}>
                <Skeleton visible={true}>
                    <div className={"task-icon"}>
                        <Image size={60} />
                    </div>
                </Skeleton>

                <Skeleton visible={true}>
                    <div className={"d-flex flex-column"}>
                        <Subheadline weight={2}>
                            Follow us on Instagram
                        </Subheadline>

                    </div>
                </Skeleton>
            </div>
            <Skeleton visible={true}>
                <div>
                    <Subheadline className={"text-warning"} level={2}>
                        + 500
                    </Subheadline>
                </div>
            </Skeleton>
        </div>
    )
}

export default TaskSkeleton