import {createSlice} from "@reduxjs/toolkit";

export const initialState = {
    currentTab: "lottos",
    showConfetti: false
}

const appSlice = createSlice({
    name: "app",
    initialState,
    reducers: {
       setCurrentTabbarItem(state, action) {
           state.currentTab = action.payload
       },
        setShowConfetti(state, action) {
            state.showConfetti = action.payload
        }
    }
})

export const {
    setCurrentTabbarItem,
    setShowConfetti
} = appSlice.actions

export default appSlice.reducer