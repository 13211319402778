import {Badge, Button, Card, Subheadline, Text} from "@telegram-apps/telegram-ui";
import Lottie from "lottie-react";
import giftLottie from "../../assets/lottie/gift.json";
import IconTon from "../icons/IconTon";
import Icon40StarFill from "../icons/Icon40StarFill";
import React from "react";

const LotteryCard = (props) => {
    const {setSelected, current} = props
    return (
        <Card
            onClick={() => {setSelected(current)}}
            className={"d-flex align-items-center flex-column gap-2 pb-3 px-3 cursor-pointer w-100"}
            type={"plain"}
        >
            <Badge
                className={"card-badge position-absolute"}
                type={"number"}
                mode={"dark"}
            >
                #50
            </Badge>
            <Lottie
                style={{
                    width: 160,
                    height: 80
                }}
                animationData={giftLottie} loop={false}
            />
            <Subheadline
                weight={2}
            >
                Giveaway Prizes
            </Subheadline>
            <Text
                weight={3}
            >
                <div className={"d-flex flex-column"}>
                    <div className={"d-flex gap-1"}>
                        <IconTon className={"ton"}/>
                        <span
                            className={"ton_num"}
                        >10</span>
                    </div>
                    <div className={"d-flex gap-1 align-items-center"}>
                        <Icon40StarFill/>
                        <span
                            className={"ton_num"}
                        >500K</span>
                    </div>
                </div>
            </Text>
            <div className={"d-flex flex-column text-center align-items-center"}>
                <Subheadline weight={2}>
                    Consolation Prize
                </Subheadline>
                <div className={"d-flex gap-1 align-items-center"}>
                    <Icon40StarFill />
                    <span
                        className={"ton_num"}
                    >50</span>
                </div>

            </div>
            <div className={"d-flex flex-column text-center"}>
                <Subheadline weight={2}>
                    Participants
                </Subheadline>
                <span
                    className={"ton_num"}
                >
                                    👥 644 / 10 000
                                </span>
            </div>
            <div className={"mt-2"}>
                <Button
                    mode={"bezeled"}
                    stretched
                    onClick={() => {setSelected(current)}}
                >
                    💎 Try Your Luck
                </Button>
            </div>

        </Card>
    )
}

export default LotteryCard