import {Avatar, Button, Caption, Card, Headline, LargeTitle, Pagination, Section, Subheadline, Title} from "@telegram-apps/telegram-ui";
import React from 'react'
import Lottie from "lottie-react";
import coinsLottie from "../../assets/lottie/coins.json";
import starCoinLottie from "../../assets/lottie/starcoin.json";
import {GiAndroidMask, GiEmerald, GiRoundStar} from "react-icons/gi";
import {Link, useNavigate} from "react-router-dom";
import IconTon from "../../Components/icons/IconTon";
import Icon40StarFill from "../../Components/icons/Icon40StarFill";
import {TonConnectButton} from "@tonconnect/ui-react";
import {useDispatch} from "react-redux";
import {setCurrentTabbarItem} from "../../slices/app/reducer";

const Profile = () => {
    const transactions = [
        {type: "reward", date: "12 Aug 23:59", amount: 500, coin: "star", text: "Lottery #32 win"},
        {type: "reward", date: "12 Aug 23:59", amount: 500, coin: "star", text: "Invited friend"},
        {type: "reward", date: "12 Aug 23:59", amount: 500, coin: "ton", text: "Task completion"},
        {type: "reward", date: "12 Aug 23:59", amount: 500, coin: "star", text: "Lottery win"},
        {type: "reward", date: "12 Aug 23:59", amount: 500, coin: "star", text: "Lottery win"},
    ]

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const goTo = (link, id) => {
        navigate(link)
        dispatch(setCurrentTabbarItem(id))
    }
    return (
        <React.Fragment>
            <div
                className={"d-flex justify-content-center gap-2 flex-column align-items-center text-center mb-3"}>
                <Lottie
                    style={{
                        width: 150,
                        height: 100
                    }}
                    animationData={coinsLottie} loop={true}
                />
                <Title
                    weight={3}
                >
                    Your Stars Balance
                </Title>
                <Headline>
                    Keep collecting and watch your total grow!
                </Headline>
            </div>
            <div className={"balance-block"}>
                <Card
                    className={"p-3 w-100 justify-content-center d-flex align-items-center flex-column"}
                >
                    <div
                        style={{
                            position:"relative",
                            right: 10,
                        }}
                        className={"d-flex gap-2 flex-row align-items-center"}>
                        <Lottie
                            className={"star-coin"}
                            style={{
                                width: 80,
                                height: 80
                            }}
                            animationData={starCoinLottie} loop={true}
                        />
                        <LargeTitle className={"star-text"} weight={1}>10,000</LargeTitle>
                    </div>
                    <div className={"d-block mb-3 caption-block"}>
                        <Headline>Star balance</Headline>
                    </div>
                    <div className={"d-flex flex-column w-100 gap-2"}>
                        <Button
                            stretched
                            onClick={() => goTo('/rewards', 'rewards')}
                            before={<GiRoundStar size={22}/> }
                        >
                            Tasks
                        </Button>

                        <Button
                            onClick={() => goTo('/lotteries', 'lottos')}
                            before={<GiEmerald size={22} />}
                        >
                            Lotteries
                        </Button>
                    </div>

                </Card>
            </div>
            <div className={"d-flex justify-content-center align-items-center text-center pt-2"}>
                <Caption weight={3}>
                    You will be able to convert your stars
                    to $SLOT token later. <Link className={"tg-link"}>Learn more</Link>
                </Caption>
            </div>
            <div className={"d-flex w-100 pt-4"}>

                <TonConnectButton
                    className={"ton-connect-wallet"}
                />
            </div>


                <div className={"d-flex pt-4 flex-column"}>
                    <Subheadline className={"mb-2 ms-3"}>
                        My transactions
                    </Subheadline>
                    <Card
                        className={"d-flex flex-column p-3"}
                    >
                    <div className={"transaction-table d-flex flex-column gap-3"}>
                        {transactions && transactions.map((el, i) => (
                            <div key={i} className={"transaction d-flex justify-content-between align-items-center"}>
                                <div className={"d-flex flex-row gap-3"}>
                                    <Avatar>
                                        {el.type == 'reward' && <GiAndroidMask />}
                                    </Avatar>
                                    <div className={"d-flex flex-column"}>
                                        <Subheadline>{el.text}</Subheadline>
                                        <Caption>{el.date}</Caption>
                                    </div>
                                </div>
                                <div className={"d-flex bonus-amount align-items-center gap-2"}>

                                    <span>
                                        {el.type == 'reward' ? '+' : '-'} {el.amount}
                                    </span>
                                    <span>
                                        {el.coin == 'star' ? <Icon40StarFill className={"transaction-icon"} /> : <IconTon className={"ton-icon" +
                                            " transaction-icon"} />}
                                    </span>
                                </div>
                            </div>
                        ))}
                    </div>
                        <div className={"pagination d-flex justify-content-center pt-2"}>
                            <Button
                                mode={"plain"}
                            >Show more</Button>
                        </div>

                    </Card>
                </div>



        </React.Fragment>
    )
}

export default Profile