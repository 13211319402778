import {ModalHeader} from "@telegram-apps/telegram-ui/dist/components/Overlays/Modal/components/ModalHeader/ModalHeader";
import {ModalClose} from "@telegram-apps/telegram-ui/dist/components/Overlays/Modal/components/ModalClose/ModalClose";
import {Icon28Close} from "@telegram-apps/telegram-ui/dist/icons/28/close";
import Lottie from "lottie-react";
import lottieRoulette from "../../assets/lottie/roulette.json";
import {Button, Headline, Modal, Title} from "@telegram-apps/telegram-ui";
import React from "react";

const ModalWheel = (props) => {
    const {modalClose, isOpen} = props
    return (
        <Modal
            dismissible={true}
            header={
                <ModalHeader
                    after={
                        <ModalClose
                            onClick={() => modalClose()}
                        >
                            <Icon28Close style={{color: 'var(--tgui--plain_foreground)'}} />
                        </ModalClose>
                    }
                >
                </ModalHeader>
            }
            open={isOpen}
        >
            <div className={"d-flex w-100 flex-column gap-3 p-3 justify-content-center align-items-center"}>
                <Lottie
                    style={{
                        width: 200
                    }}
                    animationData={lottieRoulette}
                    loop={true}
                />
                <Title
                    level="1"
                    weight="2"
                >
                    Click Spin
                </Title>
                <Headline
                    weight={3}
                >
                    Click spin and take your reward
                </Headline>
                <div className={"d-flex w-100 flex-column gap-3"}>
                    <Button>Spin</Button>
                </div>

            </div>
        </Modal>
    )
}

export default ModalWheel