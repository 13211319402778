import {Avatar, Button, Caption, Headline, Image, Skeleton, Subheadline, Title} from "@telegram-apps/telegram-ui";
import {GiAlliedStar, GiAndroidMask} from "react-icons/gi";
import React, {useEffect, useState} from "react";
import useTaskIcon from "../../hooks/useTaskIcon";

const Task = (props) => {
    const {task, onClick} = props

    const {src} = useTaskIcon(task)

    return (
        <div onClick={onClick} className={"task d-flex flex-row justify-content-between align-items-center"} role={"button"}>
            <div className={"d-flex flex-row gap-2 align-items-center w-75"}>
                <div className={"task-icon"}>
                    <Image size={65} src={src} />
                </div>

                <div className={"d-flex flex-column"}>
                    <Subheadline weight={2}>
                        {task.name}
                    </Subheadline>

                </div>
            </div>
            <div>
                <Subheadline className={"text-warning"} level={2}>
                    + {task.reward} ⭐️
                </Subheadline>
            </div>
        </div>
    )
}

export default Task