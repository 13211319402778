function useTaskIcon(task) {
    // Определяем логику для выбора иконки
    const getIcon = () => {
        switch (task?.icon) {
            case 'youtube':
                return {name: "youtube", src: "/images/icons/youtube.svg"}
            case 'telegram':
                return {name: "telegram", src: "/images/icons/telegram.svg"}
            case 'twitter':
                return {name: "twitter", src: "/images/icons/twitter.svg"}
            default:
                return {name: "default",  src: "" };
        }
    };

    return getIcon();
}

export default useTaskIcon;