import {Wheel} from "react-custom-roulette";
import {Button, Caption, Card, Headline, Section, Subheadline, Title} from "@telegram-apps/telegram-ui";
import React, {useState} from "react";
import Confetti from "react-confetti-boom";
import ModalDailyWheel from "./ModalDailyWheel";
import {api} from "../../config";
import {getRandomPrize} from "../../helpers/real_api_helper";
import {useDispatch} from "react-redux";
import {setShowConfetti} from "../../slices/app/reducer";

const PrizeWheel = () => {

    const dispatch = useDispatch()
    const data = [
        { option: '10 ⭐️',
            style: {
                backgroundColor: '#82c662',
                textColor: "white"
            }
        },
        { option: '50 ⭐️', style: {
                backgroundColor: '#a3ce43'
            }},
        { option: '100 ⭐️', style: {
                backgroundColor: '#f19f43'
            }},
        { option: '200', style: {
                backgroundColor: '#f2788d'
            }},
        { option: '300', style: {
                backgroundColor: '#8e55a2'
            }},
        { option: '400', style: {
                backgroundColor: '#6154a4'
            }},
        { option: '500', style: {
                backgroundColor: '#2c8ecd'
            } },
        { option: '600', style: {
                backgroundColor: '#44c0b8'
            } },
    ]

    const [mustSpin, setMustSpin] = useState(false);
    const [prizeNumber, setPrizeNumber] = useState(0);
    const [openDailyModal, setOpenDailyModal] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')

    const handleSpinClick = () => {
        dispatch(setShowConfetti(false))
        getRandomPrize()
            .then(res => {
                if (!mustSpin) {
                    setPrizeNumber(res.data.prizeNum);
                    setMustSpin(true);
                }
            })
            .catch(err => {
                setOpenDailyModal(true)
                setErrorMessage(err.data.message)
            })

    }

    const onStopWheel = () => {
        setMustSpin(false);
        dispatch(setShowConfetti(true))
        console.log(data[prizeNumber]);
    }

    const modalClose = () => {
        setOpenDailyModal(false)
    }

    return (
        <>
            <ModalDailyWheel
                isOpen={openDailyModal}
                modalClose={modalClose}
                errorMessage={errorMessage}
            />

            <Card className={"d-block p-2 mt-2"}>
                <div className={"d-flex align-items-center wheel flex-column"}>
                    <Wheel
                        fontFamily={"Protest Guerrilla"}
                        mustStartSpinning={mustSpin}
                        prizeNumber={prizeNumber}
                        data={data}
                        spinDuration={0.2}
                        outerBorderColor={"#c7c7c7"}
                        fontSize={30}
                        fontWeight={"bold"}
                        textColors={['white']}
                        radiusLineWidth={0}
                        outerBorderWidth={10}
                        innerBorderWidth={0}
                        pointerProps={{
                            src: "/images/arrow.svg",
                            style: {
                                transform: "rotate(180deg)",
                                right: "31px",
                                top: "32px",
                            }
                        }}


                        onStopSpinning={onStopWheel}
                    />
                </div>
                <div className={"text-center d-flex gap-2 mt-2 flex-column"}>
                    <Title>Daily spinner</Title>
                    <Button
                        className={"text-uppercase disabled"}
                        stretched
                        onClick={handleSpinClick}
                    >
                        Take it!
                    </Button>
                    <Caption>You have the opportunity to receive a random bonus every 6 hours</Caption>
                </div>


            </Card>
        </>
    )
}

export default PrizeWheel