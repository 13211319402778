import {ModalHeader} from "@telegram-apps/telegram-ui/dist/components/Overlays/Modal/components/ModalHeader/ModalHeader";
import {ModalClose} from "@telegram-apps/telegram-ui/dist/components/Overlays/Modal/components/ModalClose/ModalClose";
import {Icon28Close} from "@telegram-apps/telegram-ui/dist/icons/28/close";
import Lottie from "lottie-react";
import lottieRoulette from "../../assets/lottie/roulette.json";
import {Button, Headline, Image, Modal, Subheadline, Title} from "@telegram-apps/telegram-ui";
import React, {useState} from "react";
import useTaskIcon from "../../hooks/useTaskIcon";
import {checkTaskApi} from "../../helpers/real_api_helper";
import {toast} from "react-toastify";
import {useSelector} from "react-redux";

const ModalTask = (props) => {
    const {modalClose, isOpen, task} = props
    const {user} = useSelector(state => state.Auth)
    const [loading, setLoading] = useState(false)
    const {src} = useTaskIcon(task)

    const checkTask = () => {
        setLoading(true)
        checkTaskApi(task)
            .then(res => {

            })
            .catch(err => {
                toast.error(err.data.message)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const subscribeTelegram = (id) => {
        window.Telegram.WebApp.openTelegramLink('https://t.me/'+id)
    }

    const inviteFriends = () => {
        if (user) {
            const text = 'Welcome to Looty! Invite friends and loot TON'
            const url = `https://t.me/looty/start?startapp=${user.id}`
            window.Telegram.WebApp.openTelegramLink(`https://t.me/share/url?url=${encodeURIComponent(url)}&text=${encodeURIComponent(text)}`)
        }
    }

    return (
        <Modal
            aria-describedby={undefined}
            dismissible={true}
            onOpenChange = {(open) => {
                if (open === false)
                    modalClose()
            }}
            header={
                <ModalHeader
                    after={
                        <ModalClose
                            onClick={() => modalClose()}
                        >
                            <Icon28Close style={{color: 'var(--tgui--plain_foreground)'}} />
                        </ModalClose>
                    }
                >
                </ModalHeader>
            }
            open={isOpen}
        >
            <div className={"d-flex w-100 flex-column gap-3 p-3 justify-content-center align-items-center pb-5"}>
                <div className={"task-icon"}>
                    <Image src={src} size={90}></Image>
                </div>
                <Subheadline className={"text-warning"} level={2}>
                    + {task?.reward} ⭐️
                </Subheadline>
                <Headline
                    className={"text-center"}
                    level="1"
                    weight="2"
                >
                    {task?.name}
                </Headline>
                <div className={"requirements d-flex flex-column"}>
                    <ul>
                        <li>🫂 x2 Invite friends</li>
                        <li>🔗 x1 Channel subscription</li>
                    </ul>
                </div>
                <Headline

                    weight={1}
                >
                    Complete all tasks to receive coins
                </Headline>
                {task?.conditions && task?.conditions.map((el, i) => {
                    switch (el.type) {
                        case 'telegram_subscription':
                            let channels = el?.details?.channels
                            return (
                                <div className={"d-flex w-100 flex-column gap-2"}>
                                    {channels && channels.map(el => (
                                        <Button
                                            onClick={() => subscribeTelegram(el)}
                                        >Subscribe to @{el}</Button>
                                    ))}
                                </div>

                            )
                        case 'invite_referrals':
                            return (
                                <>

                                    <div className={"d-flex w-100 flex-column gap-2"}>
                                        <Button
                                            onClick={() => {inviteFriends()}}
                                        >Invite friend
                                        </Button>
                                    </div>
                                </>
                            )
                    }
                })}
                <div className={"d-flex flex-column w-100"}>
                    <Button
                        loading={loading}
                        onClick={() => checkTask()}
                        mode={"bezeled"}
                    >
                        Check task
                    </Button>
                </div>
            </div>
        </Modal>
    )
}

export default ModalTask