import React, {useState} from 'react'
import {Badge, Button, Caption, Card, Headline, Subheadline, Text, Title} from "@telegram-apps/telegram-ui";
import ModalSubscribe from "../../Components/custom/ModalSubscribe";
import Lottie from "lottie-react";
import giftLottie from '../../assets/lottie/gift.json'
import starsLottie from '../../assets/lottie/stars.json'
import Icon40StarFill from "../../Components/icons/Icon40StarFill";
import IconTon from "../../Components/icons/IconTon";
import LotteryCard from "../../Components/custom/LotteryCard";
const Home = () => {
    const [selectedRoulette, setSelectedRoulette] = useState(false)
    const rouletes = [
        {id: 123, name: 'Iphone 15 pro max1', description: 'Win Iphone', available_counts : 500},
        {id: 123, name: 'Iphone 15 pro max1', description: 'Win Iphone', available_counts : 500},
        {id: 123, name: 'Iphone 15 pro max1', description: 'Win Iphone', available_counts : 500},
        {id: 123, name: 'Iphone 15 pro max1', description: 'Win Iphone', available_counts : 500},
        {id: 123, name: 'Iphone 15 pro max1', description: 'Win Iphone', available_counts : 500},
        {id: 123, name: 'Iphone 15 pro max1', description: 'Win Iphone', available_counts : 500},
    ]

    const onModalClose = () => {
        setSelectedRoulette(false)
    }

    return (
        <React.Fragment>
            <ModalSubscribe
                modalClose = {onModalClose}
                isOpen={selectedRoulette}
                header={"Test"}
                description={"Test"}
            />

            <div className={"d-flex justify-content-center gap-2 flex-column align-items-center text-center mb-3"}>
                <Lottie
                    style={{
                        width: 150,
                        height: 100
                    }}
                    animationData={starsLottie} loop={true}
                />
                <Title
                    weight={3}
                >
                    Win Stars and Enter the Lottery!
                </Title>
                <Headline>
                    Earn stars to join lotteries and win exciting rewards.
                </Headline>
            </div>
            <div className={"d-flex gap-2 flex-column "}>
                {rouletes.map((el, i) => (
                    <LotteryCard
                        key={i}
                        setSelected = {setSelectedRoulette}
                        current={el}
                    />
                ))}
            </div>
        </React.Fragment>
    )
}

export default Home